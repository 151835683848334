import FormService from "../Form/FormService";
import APIService from "../API/APIService";

export default class CRUDService {
    constructor() {
        this.formService   = new FormService();
        this.apiService    = new APIService();
    }

    async addItem(apiEndpoint, addForm) {
        // build form
        const jsonFormData = this.formService.buildJsonFormData(addForm);
        // headers
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers     = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.POST(`${window.GlobalVariables.API_URL}${apiEndpoint}`, headers, jsonFormData);
        if(response.success)
            return response.result;
        else
            throw new Error(`${response.result.message}`);
    }

    async editItem(apiEndpoint, itemId, editForm) {
        // build form
        const jsonFormData = this.formService.buildJsonFormData(editForm);
        // headers
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers     = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.PUT(`${window.GlobalVariables.API_URL}${apiEndpoint}/${itemId}`, headers, jsonFormData);
        if(response.success)
            return response.result;
        else
            throw new Error(`${response.result.message}`);
    }

    async getItemById(apiEndpoint, id) {
        // build request
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.GET(`${window.GlobalVariables.API_URL}${apiEndpoint}/${id}`, headers);
        if(response.success)
            return response.result;
        else
            throw new Error(`${response.result.message}`);
    }

    async getItems(apiEndpoint, query) {
        // build request
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.GET(`${window.GlobalVariables.API_URL}${apiEndpoint}`, headers, query);
        if(response.success) {
            const items = response.result;
            return items;
        }
        else {
            throw new Error(`${response.result.message}`);
        }
    }

    async getItemsForm(apiEndpoint, queryForm) {
        // build query
        const query = (queryForm) ? this.formService.buildJsonFormData(queryForm) : null;
        // build request
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.GET(`${window.GlobalVariables.API_URL}${apiEndpoint}`, headers, query);
        if(response.success) {
            const items = response.result;
            return items;
        }
        else
            throw new Error(`${response.result.message}`);
    }

    async deleteItem(apiEndpoint, itemId) {
        // headers
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const headers     = this.formService.buildHeaders(`Bearer ${currentUser.token}`);
        // Execute request
        const response = await this.apiService.DELETE(`${window.GlobalVariables.API_URL}${apiEndpoint}/${itemId}`, headers);
        if(response.success)
            return response.result;
        else
            throw new Error(`${response.result.message}`);
    }
}