export const Routes =
{
    Home: {
        name: 'app-home',
        path: '',
        pathRegex: '^$'
    },
    Landing: {
        name: 'app-home',
        path: '',
        pathRegex: '^#/$'
    },
    About: {
        name: 'app-about',
        path: '#/about',
        pathRegex: '^#/about$'
    },
    Brands: {
        name: 'app-brands',
        path: '#/brands',
        pathRegex: '^#/brands$'
    },
    Contact: {
        name: 'app-contact',
        path: '#/contact',
        pathRegex: '^#/contact$'
    },
    GetStarted: {
        name: 'app-get-started',
        path: '#/getstarted',
        pathRegex: '^#/getstarted$'
    },
    Products: {
        name: 'app-products',
        path: '#/products',
        pathRegex: '^#/products(.*)$'
    },
    Services: {
        name: 'app-services',
        path: '#/services',
        pathRegex: '^#/services$'
    }
};