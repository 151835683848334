function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <app-landing></app-landing>
        <app-services></app-services>
        <div class="home-products">
            <div class="home-products-intro">
                <h2 class="text-center text-gray py-3">Discover The Best Food Products</h2>
            </div>
            <div class="home-products-list">
                <app-product data-image="assets/img/waffles/Sarna_Stroop_wafel.jpeg" data-imagepadding="1.2rem">
                    <span slot="title">Caramel Stroop Wafel</span>
                    <span slot="subtitle">400g<br/>Carton Count: 15</span>
                </app-product>
                <app-product data-image="assets/img/waffles/Sarna_MilkChocolate_wafel.jpeg" data-imagepadding="1.2rem">
                    <span slot="title">Milk Chocolate Wafel</span>
                    <span slot="subtitle">400g<br/>Carton Count: 15</span>
                </app-product>
                <app-product data-image="assets/img/waffles/Sarna_WhiteChocolate_wafel.jpeg" data-imagepadding="1.2rem">
                    <span slot="title">White Chocolate Wafel</span>
                    <span slot="subtitle">400g<br/>Carton Count: 15</span>
                </app-product>
                <app-product data-image="assets/img/waffles/Sarna_Pistachio_IceCream.jpg" data-imageheight="9rem" data-imagepadding="1rem">
                    <span slot="title">Pistachio Arabia Ice Cream</span>
                    <span slot="subtitle">1Kg<br/>Carton count: 5</span>
                </app-product>
                <app-product data-image="assets/img/pastry/Sarna_HalfFriedKebbe.jpg" data-imageheight="9rem" data-imagepadding="1rem">
                        <span slot="title">Half Fried Kebbe</span>
                        <span slot="subtitle">400g (10 Pieces)<br/>Carton count: 10</span>
                    </app-product>
                <app-product data-image="assets/img/pastry/Sarna_Cheese_Burak.jpg" data-imageheight="9rem" data-imagepadding="1rem">
                    <span slot="title">Cheese Burak</span>
                    <span slot="subtitle">400g (12 Pieces)<br/>Carton count: 10</span>
                </app-product>
            </div>
            <div class="home-products-intro">
                <a class="text-center btn-view-home-products" href="#/products">View more</a>
            </div>
        </div>
        <app-map></app-map>
    `;
    return template;
}

export default class HomeComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        
    }

    disconnectedCallback() {

    }
}