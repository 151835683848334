function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <div class="px-4 pt-2 fade-in">
            <h1>Brands</h1>
        </div>
        <div class="pt-2 pb-3 products-list fade-in">
            <app-product data-image="assets/img/brands/montanaeg.com.jpeg" data-imageheight="9rem" data-imagepadding="0rem" onclick="window.open('https://montanaeg.com', '_blank')" style="cursor:pointer;">
                <span slot="title">Montana</span>
                <span slot="subtitle">Montana Eg</span>
            </app-product>
            <app-product data-image="assets/img/Sarna.svg" data-imageheight="9rem" data-imagepadding="1rem" onclick="location='#/products'" style="cursor:pointer;">
                <span slot="title">Sarna</span>
                <span slot="subtitle">Our Products</span>
            </app-product>
            <app-product data-image="assets/img/brands/homeofswitz.com.jpeg" data-imageheight="9rem" data-imagepadding="0rem" onclick="window.open('https://homeofswitz.com', '_blank')" style="cursor:pointer;">
                <span slot="title">Switz</span>
                <span slot="subtitle">Home of Switz</span>
            </app-product>
            <app-product data-image="assets/img/brands/med-arthro.com_welcome.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('https://med-arthro.com/welcome', '_blank')" style="cursor:pointer;">
                <span slot="title">Med Arthro</span>
                <span slot="subtitle">HC Aktiv</span>
            </app-product>
            <app-product data-image="assets/img/brands/med-arthro.com_hyalutidin.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('https://med-arthro.com/hyaluditin', '_blank')" style="cursor:pointer;">
                <span slot="title">Hyalutidin®</span>
                <span slot="subtitle">Mobility HCC</span>
            </app-product>
            <app-product data-image="assets/img/brands/med-arthro.com_myosedam.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('https://med-arthro.com/myosedam', '_blank')" style="cursor:pointer;">
                <span slot="title">MyoSedam</span>
                <span slot="subtitle">Muscle Strength and recovery</span>
            </app-product>
            <app-product data-image="assets/img/brands/med-arthro.com_new-page-4.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('https://med-arthro.com/new-page-4', '_blank')" style="cursor:pointer;">
                <span slot="title">TendoSedam</span>
                <span slot="subtitle">Tendons & Ligaments Dietary Supplement</span>
            </app-product>
            <app-product data-image="assets/img/brands/med-arthro.com_duovital-facial-cream.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('https://med-arthro.com/duovital-facial-cream', '_blank')" style="cursor:pointer;">
                <span slot="title">DuoVital</span>
                <span slot="subtitle">Reojuoveonate</span>
            </app-product>
            <app-product data-image="assets/img/brands/menapharm.com.jpeg" data-imageheight="9rem" data-imagepadding="2rem" onclick="window.open('http://menapharm.com', '_blank')" style="cursor:pointer;">
                <span slot="title">Menapharm</span>
                <span slot="subtitle">Menapharm Deutschland</span>
            </app-product> 
        </div>
    `;
    return template;
}

export default class BrandsComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        
    }

    disconnectedCallback() {

    }
}