// App & Router
import App from './app';
import Router from './Router/Router';
// Config
import { Routes } from './config/Routes';
// Service
import UIService from './Service/UI/UIService';
// Views
import MainView from './Views/MainView';
import RouterView from './Views/RouterView';
// Components
import Components from './components';

class Index {
    constructor() {
        this.app        = new App("#app");
        this.router     = new Router(this.app);
        this.components = new Components();
        this.uiService  = new UIService();
    }

    start() {
        this.addComponents();
        this.addRoutes();
        this.load();
    }

    async loadCss() {
        const stylescss = await (await fetch(window.GlobalVariables.CSS_FILE_NAME)).text();
        const styles = stylescss.replace(/\n|\r/g, "");
        window.GlobalVariables.styles = styles;
    }

    async load() {
        await this.loadCss();
        this.components.defineComponents();
        const { hash } = window.location;
        this.uiService.toggleUIForUser(true);
        // Route
        Object.entries(Routes).forEach((route) => {
            const [name, props] = route;
            if(hash.match(new RegExp(props.pathRegex)))
                this.app.instantiateApp(props);
        });
    }

    addComponents() {
        // Add Main
        this.app.addComponent({
            name: 'main-view',
            view: MainView
        });

        // Add Router
        this.app.addComponent({
            name: 'router-view',
            view: RouterView
        });

        // Others
        this.components.addComponents(this.app);
    }

    addRoutes() {
        Object.entries(Routes).forEach((route) => {
            const [name, props] = route;
            const result = this.router.addRoute(props.name, props.pathRegex);
        });
    }
}

new Index().start();