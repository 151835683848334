function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <app-introduction data-href="#/products" data-isbtnaction="true">
            <span slot="aboutus">About Us</span>
            <span slot="title">Sarna Food</span>
            <span slot="description">We aim at bringing food products that are only up to the level of our customers’ expectations. We attempt to change customer perceptions in the right direction by offering the benefits customers seek as we offer services that are primarily customer-driven.</span>
            <span slot="btnactiontext">View our products</span>
        </app-introduction>
        <section class="d-flex flex-row flex-wrap mx-auto my-3">
            <div class="d-flex flex-column align-items-center flex-grow-1 p-2">
                <h1 class="title text-left align-self-start">Our Mission</h1>
                <h4 class="h4-about word-wrap-about-description font-weight-normal">We seek to maintain and build unique and high profiled business relationships with our clients through our commitments to customer-driven needs in attractive, stimulating and inspiring environments.</h4>
            </div>
            <div class="d-flex flex-column align-items-center flex-grow-1 p-2">
                <h1 class="title text-left align-self-start">Our Vision</h1>
                <h4 class="h4-about word-wrap-about-description font-weight-normal">We aim to be at the fore in importing and exporting high quality products.</h4>
            </div>
        </section>
        <section class="d-flex flex-column justify-content-center align-items-center bg-lightwhite py-3">
            <h1 class="h1-about about-title">Our Values</h1>
            <h2 class="h2-about">Client Comfort</h2>
            <h4 class="h4-about word-wrap-about-value">Our most important priority is client comfort.</h4>
            <h2 class="h2-about">Honesty & Integrity</h2>
            <h4 class="h4-about word-wrap-about-value">We’re honest, transparent and committed to doing what’s best for our customers and our company.</h4>
            <h2 class="h2-about">Excellence</h2>
            <h4 class="h4-about word-wrap-about-value">We always strive for excellence and are unconstrained by the way things have always been done. We creatively approach every task and obstacle to find original solutions.</h4>
            <h2 class="h2-about">Quality & High Professionalism</h2>
            <h4 class="h4-about word-wrap-about-value">We deliver with quality on time .. every time.</h4>
        </section>
        <app-get-started>
            <span slot="callToAction">Get Started with us today</span>
        </app-get-started>
    `;
    return template;
}

export default class AboutComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        
    }

    disconnectedCallback() {

    }
}