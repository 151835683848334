

function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <footer class="mx-auto">
            <!--Info-->
            <div class="d-flex flex-column">
                <h3 class="font-weight-normal text-black mb-2">Sarna Food</h3>
                <img src="assets/svg/Sarna.svg" class="footer-brand-img" alt="Logo" />
            </div>
            <!--Placeholder-->
            <div class="footer-links">
            <h3 class="font-weight-normal text-black mb-2">Business</h3>
            <a href="" onclick="console.log('KVK clicked.');return false;">KVK. 74842331</a>
            <a href="" onclick="console.log('BTWW clicked.');return false;">BTW. NL002491543B64</a>
            </div>
            <!--Pages-->
            <div class="footer-links">
                <h3 class="font-weight-normal text-black mb-2">Pages</h3>
                <a href="#/">Home</a>
                <a href="#/about">About Us</a>
                <a href="#/brands">Brands</a>
                <a href="#/contact">Contact Us</a>
                <a href="https://med-arthro.com/" target="_blank">Nutritional Supplements (Med Arthro)</a>
            </div>
            <!--Contact-->
            <div class="footer-links">
                <h3 class="font-weight-normal text-black mb-2">Contact</h3>
                <a href="tel:0031642539666" class="font-weight-normal text-gray">0031 642 539 666</a>
                <a href="tel:004917634157233" class="font-weight-normal text-gray">0049 176 3415 7233</a>
                <a href="mailto:info@sarnafood.nl" class="font-weight-normal text-gray">info@sarnafood.nl</a>
                <p>Nijveheidsweg 1b<br>4731 CZ Oudenbosch<br>Netherlands</p>
            </div>
        </footer>
        <div class="text-center">
            <h4 class="footer-copy text-black my-1">&copy; <span id="thisYear"></span> Sarna. All rights reserved.</h4>
        </div>
    `;
    return template;
}

export default class FooterComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        // Get the current year and append in copyright section
        this.shadowRoot.querySelector("#thisYear").innerText = new Date().getFullYear();
    }

    disconnectedCallback() {

    }
}

