function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <link href='https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css' rel='stylesheet' />
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <div class="d-flex flex-column my-1">
            <h1 class="map-header mt-1 mb-2">Location</h1>
            <div class="map-container" id="mapContainer"></div>
        </div>
    `;
    return template;
}

export default class MapComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.accessToken =
      "pk.eyJ1IjoiZ2xvcmlvdXM3MyIsImEiOiJja2wyeG9zdDkwY29vMnZyd3k1OWtxZDNtIn0.dPcAaYVSvlKRtU9ws8nrzQ";
    }

    connectedCallback() {
        // Map Token
        mapboxgl.accessToken = this.accessToken;
        // Init Map
        const mapContainer = this.shadowRoot.querySelector("#mapContainer");
        const map = new mapboxgl.Map({
            container: mapContainer,
            center: [4.554153957464428, 51.590462760236655],
            zoom: 3,
            style: "mapbox://styles/mapbox/streets-v11",
        });
        // Set options
        const marker = new mapboxgl.Marker({
            color: "#c26434",
            draggable: false,
            scale: 2,
        }).setLngLat([4.554153957464428, 51.590462760236655]).addTo(map);
    }

    disconnectedCallback() {

    }
}