// Config
import { Routes } from './config/Routes';
// Main
import MainView from "./Views/MainView";
import RouterView from "./Views/RouterView";
// UI
import AlertsComponent from "./Component/Alert/AlertsComponent";
import AlertComponent from "./Component/Alert/AlertComponent";
import FooterComponent from './Component/Footer/FooterComponent';
import AccordionComponent from "./Component/UI/Accordion/AccordionComponent";
import FileComponent from "./Component/UI/File/FileComponent";
import ModalComponent from "./Component/UI/Modal/ModalComponent";
import NavbarComponent from "./Component/UI/Navbar/NavbarComponent";
import SearchComponent from "./Component/UI/Search/SearchComponent";
import SelectComponent from "./Component/UI/Select/SelectComponent";
import SvgIconComponent from "./Component/UI/SvgIcon/SvgIconComponent";
import UserMenuComponent from "./Component/UI/UserMenu/UserMenuComponent";
// Components
import HomeComponent from './Component/Home/HomeComponent';
import AboutComponent from './Component/About/AboutComponent';
import BrandsComponent from './Component/Brands/BrandsComponent';
import ContactComponent from './Component/Contact/ContactComponent';
import GetStartedComponent from './Component/GetStarted/GetStartedComponent';
import IntroductionComponent from './Component/Introduction/IntroductionComponent';
import LandingComponent from './Component/Landing/LandingComponent';
import MapComponent from './Component/Map/MapComponent';
import ProductIntroComponent from './Component/Products/ProductIntroComponent';
import ProductsComponent from './Component/Products/ProductsComponent';
import ProductComponent from './Component/Products/ProductComponent';
import ServicesComponent from './Component/Services/ServicesComponent';

export default class Components {
    constructor() { }

    defineComponents() {
        // Main
        customElements.define('main-view', MainView);
        customElements.define('router-view', RouterView);
        // UI
        customElements.define('app-alert', AlertComponent);
        customElements.define('app-alerts', AlertsComponent);
        customElements.define('app-footer', FooterComponent);
        customElements.define('app-accordion', AccordionComponent);
        customElements.define('app-file', FileComponent);
        customElements.define('app-modal', ModalComponent);
        customElements.define('app-navbar', NavbarComponent);
        customElements.define('app-search', SearchComponent);
        customElements.define('app-select', SelectComponent);
        customElements.define('app-svg-icon', SvgIconComponent);
        customElements.define('app-user-menu', UserMenuComponent);
        // Components
        customElements.define('app-home', HomeComponent);
        customElements.define('app-about', AboutComponent);
        customElements.define('app-brands', BrandsComponent);
        customElements.define('app-landing', LandingComponent);
        customElements.define('app-introduction', IntroductionComponent);
        customElements.define('app-contact', ContactComponent);
        customElements.define('app-get-started', GetStartedComponent);
        customElements.define('app-products', ProductsComponent);
        customElements.define('app-product', ProductComponent);
        customElements.define('app-product-introduction', ProductIntroComponent);
        customElements.define('app-services', ServicesComponent);
        customElements.define('app-map', MapComponent);
    }

    addComponents(app) {
        app.addComponent({
            name: Routes.Home.name,
            view: HomeComponent
        });
        app.addComponent({
            name: Routes.Landing.name,
            view: HomeComponent
        });
        app.addComponent({
            name: Routes.About.name,
            view: AboutComponent
        });
        app.addComponent({
            name: Routes.Brands.name,
            view: BrandsComponent
        });
        app.addComponent({
            name: Routes.Contact.name,
            view: ContactComponent
        });
        app.addComponent({
            name: Routes.GetStarted.name,
            view: GetStartedComponent
        });
        app.addComponent({
            name: Routes.Products.name,
            view: ProductsComponent
        });
        app.addComponent({
            name: Routes.Services.name,
            view: ServicesComponent
        });
    }
}