function markupTemplate() {
    const template = document.createElement('template');
    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <header>
            <div class="nav nav-box-shadow">
                <input type="checkbox" id="nav-check">
                <div class="nav-header">
                    <div class="nav-title">
                        <img class="navbar-brand-img" src="assets/svg/Sarna.svg" alt="Logo" />
                    </div>
                </div>
                <div class="nav-btn">
                    <label for="nav-check">
                        <span></span>
                        <span></span>  
                        <span></span>
                    </label>
                </div>
                <div class="nav-items">
                    <a id="navhome" href="#/">Home</a>
                    <a id="navabout" href="#/about">About Us</a>
                    <a id="navbrands" href="#/brands">Brands</a>
                    <a id="navcontact" href="#/contact">Contact Us</a>
                </div>
            </div>
        </header>
    `;
    return template;
}

export default class NavbarComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.pageScroll = this.pageScroll.bind(this);
        window.onscroll = this.pageScroll;
    }

    connectedCallback() {
      // attrs
      this.activateNavItems();
      this._lastScrollTop = 0;
      // events
      this.changeActiveElement();
      document.addEventListener("LocationChangedEvent", (e) => this.changeActiveElement());
      this.shadowRoot.querySelector('.nav-items').addEventListener('click', (e) => {
          this.shadowRoot.querySelector('#nav-check').checked =  false;
      });
    }

    disconnectedCallback() {
        
    }

    pageScroll() {
      const navbar = this.shadowRoot.querySelector(".nav");
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if(scrollTop > this._lastScrollTop && scrollTop > 80)
          navbar.style.top = '-80px';
      else
          navbar.style.top = '0';
      this._lastScrollTop = scrollTop;
    }

    activateNavItems() {
      // Page locations
      this._locationElements = {
        "navhome": "#/",
        "navbrands": "#/brands",
        "navabout": "#/about",
        "navcontact": "#/contact",
      };
    }

    changeActiveElement() {
      // 1. check current route
      const { hash } = window.location;
      // 2. Assign active to mapped element
      const navitems = this.shadowRoot.querySelector(".nav-items").children;
      if(hash == "")
        this.shadowRoot.querySelector("#navhome").className = "active";
      else
        for(let navitem of navitems) {
          if(hash == this._locationElements[navitem.id])
            navitem.className = "active";
          else
            navitem.classList.remove("active");
        }
      this.shadowRoot.querySelector("#nav-check").checked = "";
    }
}