function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <section class="get-started">
            <h1 class="h1-get-started"><slot name="callToAction"></slot></h1>
            <a class="btn-get-started-contact" href="#/contact">Contact Us</a>
        </section>
    `;
    return template;
}

export default class GetStartedComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        
    }

    disconnectedCallback() {

    }
}