function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <div class="bg-introduction-main fade-in">
            <div class="d-flex flex-row justify-content-center align-items-center mx-auto">
                <div class="d-flex flex-column justify-content-start pt-2">
                    <h4 class="text-white"><slot name="aboutus"></slot></h4>
                    <h2 class="introduction-title text-white word-wrap-introduction-landing"><slot name="title"></slot></h2>
                    <h4 class="text-white introduction-description"><slot name="description"></slot></h4>
                    <a class="btn-introduction-action"><slot name="btnactiontext"></slot></a>
                </div>
                <div>
                
                </div>
            </div>
        </div>
    `;
    return template;
}

export default class IntroductionComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        const isBtnActionDisplayed = this.getAttribute("data-isbtnaction");
        const href = this.getAttribute("data-href");
        const desktopHeight = this.getAttribute("data-desktopheight");
        if(isBtnActionDisplayed && isBtnActionDisplayed == "false")
            this.shadowRoot.querySelector(".btn-introduction-action").style.display = "none";
        if(href)
            this.shadowRoot.querySelector(".btn-introduction-action").href = href;
        if(desktopHeight && window.screen.width > 576) {
            this.shadowRoot.querySelector(".bg-introduction-main").style.minHeight = desktopHeight;
            this.shadowRoot.querySelector(".bg-introduction-main").style.maxHeight = desktopHeight;
        }
    }

    disconnectedCallback() {

    }
}