function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <app-product-introduction data-isbtnaction="false" data-desktopheight="60vh">
            <span slot="aboutus">Our Products</span>
            <span slot="title">Sarna Food</span>
            <span slot="subtitle"></span>
            <span slot="description">We offer a variety of the best chocolates, wafels, and vegetables in the market.</span>        
        </app-product-introduction>
        <div class="container bg-lightwhite">
            <div class="pt-1 pb-3">
                <div class="px-4 py-1">
                    <h1>Pastry</h1>
                </div>
                <div class="products-list">
                    <app-product data-image="assets/img/pastry/Sarna_HalfFriedKebbe.jpg" data-imageheight="9rem" data-imagepadding="2rem">
                        <span slot="title">Half Fried Kebbe</span>
                        <span slot="subtitle">400g (10 Pieces)<br/>Carton count: 10</span>
                    </app-product>
                    <app-product data-image="assets/img/pastry/Sarna_Cheese_Burak.jpg" data-imageheight="9rem" data-imagepadding="2rem">
                        <span slot="title">Cheese Burak</span>
                        <span slot="subtitle">400g (12 Pieces)<br/>Carton count: 10</span>
                    </app-product>
                </div>
                <div class="px-4 py-1">
                    <h1>Cookies, Waffles, and Ice Cream</h1>
                </div>
                <div class="products-list">
                    <app-product data-image="assets/img/waffles/Sarna_Stroop_wafel.jpeg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">Caramel Stroop Wafel</span>
                        <span slot="subtitle">Clipped Bag 350g (10 pieces)<br/>Carton Count: 15</span>
                    </app-product>
                    <app-product data-image="assets/img/waffles/Sarna_MilkChocolate_wafel.jpeg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">Milk Chocolate Wafel</span>
                        <span slot="subtitle">Mini 4 pieces<br/>Carton Count: 15</span>
                    </app-product>
                    <app-product data-image="assets/img/waffles/Sarna_WhiteChocolate_wafel.jpeg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">White Chocolate Wafel</span>
                        <span slot="subtitle">Mini 4 pieces<br/>Carton Count: 15</span>
                    </app-product>
                    <app-product data-image="assets/img/waffles/Sarna_Stroop_wafel.jpeg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">Caramel Stroop Wafel</span>
                        <span slot="subtitle">Mini 240g<br/>Carton Count: 15</span>
                    </app-product>
                    <app-product data-image="assets/img/waffles/Sarna_Pistachio_IceCream.jpg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">Pistachio Arabia Ice Cream</span>
                        <span slot="subtitle">1Kg<br/>Carton count: 5</span>
                    </app-product>
                    <app-product data-image="assets/img/waffles/Sarna_Pistachio_IceCream.jpg" data-imageheight="8rem" data-imagepadding="1rem">
                        <span slot="title">Pistachio Arabia Ice Cream</span>
                        <span slot="subtitle">500g<br/>Carton count: 10</span>
                    </app-product>
                </div>
            </div>
        </div>
        <app-get-started>
            <span slot="callToAction">Interested?</span>
        </app-get-started>
    `;
    return template;
}

export default class ProductsComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        this._interval = 0;
    }

    disconnectedCallback() {

    }
}