import FormService from '../../Service/Form/FormService';
import APIService from '../../Service/API/APIService';

function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <div class="contact-page fade-in">
            <div class="bg-contact">
                <div class="contact-container">
                    <h1>Contact Information</h1>
                    <h4 class="font-weight-normal">Fill up the form so our team can get notified and contact you as soon as possible.</h4>
                    <section class="company-information">
                        <!--Phone-->
                        <svg class="contact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"/></svg>
                        <h3><a class="contact-link" href="tel:0031642539666">0031 642 539 666</a>, Netherlands<br/><a class="contact-link" href="tel:004917634157233">0049 176 3415 7233</a>, Germany</h3>
                        <!--Email-->
                        <svg class="contact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/></svg>
                        <h3><a class="contact-link" href="mailto:info@sarnafood.nl">info@sarnafood.nl</a></h3>
                        <!--Location 1-->
                        <svg class="contact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>
                        <h3>Nijveheidsweg 1b, 4731 CZ Oudenbosch, Netherlands</h3>
                        <!--Location 2-->
                        <svg class="contact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>
                        <h3>Haberechtstr. 5, 12099 Berlin, Germany</h3>
                    </section>
                </div>
                <div class="subscribe-container">
                    <img class="svg-subscribe" src="assets/svg/undraw_subscribe.svg" alt="undraw_subscribe.svg"/>
                </div>
            </div>
            <div class="bg-contact-md">
                <h1>Contact Us</h1>
                <form class="contact-form">
                    <div class="large-group">
                        <!--Name-->
                        <div class="small-group">
                            <label for="name">Name</label>
                            <input id="fullName" type="text" name="fullName" required/>
                        </div>
                        <!--Email-->
                        <div class="small-group">
                            <label for="email">Email</label> 
                            <input id="emailAddress" type="email" name="emailAddress" required/>
                        </div>
                        <!--Phone-->
                        <div class="small-group">
                            <label for="phone">Phone</label> 
                            <input id="phoneNumber" type="text" name="phoneNumber" required/>
                        </div>
                        <!--Message-->
                        <div class="textarea-div">
                            <label for="message">Message</label>
                            <textarea id="message" type="text" name="message" required></textarea>
                        </div>
                        <div class="submit-section">
                        <button class="btn" id="submit" type="submit" name="submit">
                            Send  <svg class="send-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M464 4.3L16 262.7C-7 276-4.7 309.9 19.8 320L160 378v102c0 30.2 37.8 43.3 56.7 20.3l60.7-73.8 126.4 52.2c19.1 7.9 40.7-4.2 43.8-24.7l64-417.1C515.7 10.2 487-9 464 4.3zM192 480v-88.8l54.5 22.5L192 480zm224-30.9l-206.2-85.2 199.5-235.8c4.8-5.6-2.9-13.2-8.5-8.4L145.5 337.3 32 290.5 480 32l-64 417.1z"/></svg>
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `;
    return template;
}

export default class ContactComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        // Event listeners
        this.shadowRoot.querySelector("form").addEventListener('submit', async (e) => {
            e.preventDefault();
            this.showSpinner();
            await this.submitContactInformation();
            this.showSent();
            return false;
        });
        // Objects
        this.formService = new FormService();
        this.apiService  = new APIService();    
    }

    disconnectedCallback() {
        this.formService = null;
        this.apiService  = null;
    }

    showSpinner() {
        const button = this.shadowRoot.querySelector(".btn");
        button.innerHTML = /*html*/`
          <div class="spinner rotate"></div>
        `;
    }
  
    showSent() {
        const button = this.shadowRoot.querySelector(".btn");
        button.innerHTML = /*html*/`
            <svg class="check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        `;
    }

    async submitContactInformation() {
        const headers = this.formService.buildHeaders();
        const jsonFormData = this.formService.buildJsonFormData(this.shadowRoot.querySelector("form"));
        const response = await this.apiService.performPostHttpRequest(`${window.GlobalVariables.API_URL}/api/Lead`, headers, jsonFormData);
        console.log(JSON.stringify(response));
    }
}