function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <div class="product-card">
            <div class="product-info">
                <img class="product-card-img" alt="productimage.png" id="productimage">
                <h3 class="text-gray my-0"><slot name="title"></slot></h3>
                <h4 class="text-red mb-05"><slot name="subtitle"></slot></h4>
                <p class="text-gray mb-1"><slot name="description"></slot></p>  
            </div>
        </div>
    `;
    return template;
}

export default class ProductComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        const productCardImage = this.shadowRoot.querySelector(".product-card-img");
        productCardImage.src = this.getAttribute("data-image");
        if(this.getAttribute("data-imageheight"))
            productCardImage.style.height = this.getAttribute("data-imageheight");
        if(this.getAttribute("data-imagepadding"))
            productCardImage.style.padding = this.getAttribute("data-imagepadding");
    }

    disconnectedCallback() {

    }
}