function markupTemplate() {
    const template = document.createElement('template');

    template.innerHTML = /*html*/`
        <style>
        ${window.GlobalVariables.styles}
        </style>
        <section class="services bg-white">
            <div class="d-flex flex-column bg-white justify-content-center align-items-center fade-in">
                <img class="img-services-main" src="assets/svg/service.svg" alt="service.svg" id="serviceimage">
                <h1 class="text-black mb-1">Service</h1>
                <p class="text-gray text-center word-wrap-10">Best Service, Right Time, Right People.</p>
            </div>
            <div class="d-flex flex-column bg-white justify-content-center align-items-center fade-in">
                <img class="img-services-main" src="assets/svg/frozen.svg" alt="forzen.svg" id="frozenimage">
                <h1 class="text-black mb-1">Food</h1>
                <p class="text-gray text-center word-wrap-10">Get a Fresh at New taste.</p>
            </div>
            <div class="d-flex flex-column bg-white justify-content-center align-items-center fade-in">
                <img class="img-services-main" src="assets/svg/delivery.svg" alt="delivery.svg" id="deliveryimage">
                <h1 class="text-black mb-1">Delivery</h1>
                <p class="text-gray text-center word-wrap-10">Deliver it through us.</p>
            </div>
        </section>
    `;
    return template;
}

export default class ServicesComponent extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        const template = markupTemplate();        
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        
    }

    disconnectedCallback() {

    }
}